/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui'
import React from 'react'
import Corner from 'components/Corner'

const Caption = ({size, title}) =>
  <figcaption sx={{
    transition: 'opacity .5s, transform .25s',
    transform: 'translate(0, 0)',
    opacity: 1,
    position: 'absolute',
    zIndex: '200',
    bottom: '0',
    right: 0,
    width: '83%',
    p: '3pt 5pt 0',
    borderTopLeftRadius: 'default',
    borderBottom: 'none',
    bg: 'white'
  }}>
    <Box sx={{position: 'absolute', bottom: `100%`, right: `0`}}><Corner/></Box>
    <Box sx={{position: 'absolute', bottom: `0`, right: `100%`}}><Corner /></Box>
    {`${title}`}
  </figcaption>

const PageTextContent = ({ title, info, size }) => {
  return (
    <Box sx={{
      position: 'relative',
      gridColumn: ['span 4', null, 'span 5', 'span 6', 'span 5'],
      alignItem: 'stretch',
      bg: 'secondary',
      color: 'black',
      border: 'none',
      borderColor: 'seconday',
      borderRadius: 'default',
      p: 1,
      pb: 6
    }}>
      <Text as='p' variant='frxl'>{info}</Text>
      <Caption size={'large'} title={title}  />
    </Box>
  )
}

export default PageTextContent