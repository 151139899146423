import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import { Index } from 'react-instantsearch-dom'
import SEO from 'components/seo'
import InfiniteHits from 'components/InfiniteHits'
import InitiativeTile from 'components/InitiativeTile'
import PageTextContent from 'components/PageTextContent'


const NotFoundPage = ({data}) => {
  const slots = [
    {
      injectAt: ({ position }) => position === 0,
      getHits: ({ position, resultsByIndex }) => {
        return position === 0 ? [{
          title: `404. That's an error.`,
          info: 'The requested URL /404 was not found on the server. Therefore we have redirected you to the landing page.',
          size: 'large',
          slotComponent: PageTextContent
        }] : []
      }
    },
  ]

  return (
    <React.Fragment>
      <SEO title='404: Not found' description={data?.site?.description?.localized || ''} />
      <Container sx={{ px: 2, flexGrow: '1' }}>
        <InfiniteHits
          slots={() => slots}
          hitComponent={InitiativeTile}
        />
      </Container>
    </React.Fragment>
  )
}


export const query = graphql`
  query NotFoundPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...LocalizedSettings
    }
  }
`;

export default NotFoundPage
